import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`I veckans avsnitt tar vi en titt på nya LibreOffice, Have I Been Pwned, Nextcloud Forms och nya Linux 5.8. Vi diskuterar motvilligt Trumps senaste snedsteg när Kungen kommer in och räddar situationen och bäddar för en trevlighet som har revolutionerat Git för Seb. Detta och mer i veckans avsnitt av Trevlig Mjukvara.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`LibreOffice 7.0: `}<a parentName="li" {...{
          "href": "https://blog.documentfoundation.org/blog/2020/08/05/announcement-of-libreoffice-7-0/"
        }}>{`https://blog.documentfoundation.org/blog/2020/08/05/announcement-of-libreoffice-7-0/`}</a>{` `}</li>
      <li parentName="ul">{`Have I Been Pwned går open source: `}<a parentName="li" {...{
          "href": "https://www.troyhunt.com/im-open-sourcing-the-have-i-been-pwned-code-base/"
        }}>{`https://www.troyhunt.com/im-open-sourcing-the-have-i-been-pwned-code-base/`}</a></li>
      <li parentName="ul">{`Nextcloud Forms: `}<a parentName="li" {...{
          "href": "https://nextcloud.com/blog/nextcloud-forms-is-here-to-take-on-gafam/"
        }}>{`https://nextcloud.com/blog/nextcloud-forms-is-here-to-take-on-gafam/`}</a></li>
      <li parentName="ul">{`Linux 5.8: `}<a parentName="li" {...{
          "href": "https://kernelnewbies.org/Linux_5.8"
        }}>{`https://kernelnewbies.org/Linux_5.8`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Trump pausar Open Technology Fund: `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/linux/comments/i2vsun/trump_administration_is_withholding_20m_approved/"
        }}>{`https://www.reddit.com/r/linux/comments/i2vsun/trump_administration_is_withholding_20m_approved/`}</a></li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`lazygit: `}<a parentName="li" {...{
          "href": "https://github.com/jesseduffield/lazygit"
        }}>{`https://github.com/jesseduffield/lazygit`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex Rust-äventyr`}</h3>
    <ul>
      <li parentName="ul">{`Ont om tid`}</li>
      <li parentName="ul">{`Ungt ekosystem`}<ul parentName="li">
          <li parentName="ul">{`Inte samma utbud av bibliotek`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://0ver.org/"
            }}>{`https://0ver.org/`}</a></li>
        </ul></li>
    </ul>
    <h3>{`Sebs npm-äventyr`}</h3>
    <ul>
      <li parentName="ul">{`Femton papiljotter`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Dator till skolarbete, med skolans krav: 4GB ram, i7 eller motsvarande, windows`}<ul parentName="li">
          <li parentName="ul">{`Vad tycker vi?`}</li>
          <li parentName="ul">{`Vad tycker lyssnarna?`}</li>
        </ul></li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      